import { useMatches } from "@remix-run/react";

import getParentPath from "../util/getParentPath.js";

export const useCurrentPath = () => {
  const matches = useMatches();

  const lastMatch = matches[matches.length - 1];
  const path = lastMatch.pathname;
  return path;
};

export const useParentPath = () => {
  const path = useCurrentPath();
  return getParentPath(path);
};
